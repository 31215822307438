@font-face {
  font-family: 'icomoon';
  src: url('../fonts/icomoon.eot?dld327');
  src: url('../fonts/icomoon.eot?dld327#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?dld327') format('truetype'),
    url('../fonts/icomoon.woff?dld327') format('woff'),
    url('../fonts/icomoon.svg?dld327#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-geofence-i:before {
  content: "\e933";
}

.icon-geofence-o:before {
  content: "\e934";
}

.icon-notifcation-o:before {
  content: "\e932";
}

.icon-filter:before {
  content: "\e931";
}

.icon-distance:before {
  content: "\e92e";
}

.icon-language:before {
  content: "\e92f";
}

.icon-offboard:before {
  content: "\e930";
}

.icon-collapse:before {
  content: "\e92c";
}

.icon-location:before {
  content: "\e92d";
}

.icon-expand:before {
  content: "\e929";
}

.icon-fuel:before {
  content: "\e92a";
}

.icon-odometer:before {
  content: "\e92b";
}

.icon-delete:before {
  content: "\e91d";
}

.icon-download:before {
  content: "\e91e";
}

.icon-driver:before {
  content: "\e91f";
}

.icon-export:before {
  content: "\e920";
}

.icon-eye:before {
  content: "\e921";
}

.icon-globe:before {
  content: "\e922";
}

.icon-import:before {
  content: "\e923";
}

.icon-information:before {
  content: "\e924";
}

.icon-print:before {
  content: "\e925";
}

.icon-replace:before {
  content: "\e926";
}

.icon-truck:before {
  content: "\e927";
}

.icon-upload:before {
  content: "\e928";
}

.icon-clock:before {
  content: "\e91b";
}

.icon-sos:before {
  content: "\e91c";
}

.icon-expense:before {
  content: "\e919";
}

.icon-revenue:before {
  content: "\e91a";
}

.icon-minus:before {
  content: "\e918";
}

.icon-calendar:before {
  content: "\e911";
}

.icon-down-arrow:before {
  content: "\e912";
}

.icon-more-ver-f:before {
  content: "\e913";
}

.icon-rupee:before {
  content: "\e914";
}

.icon-search:before {
  content: "\e915";
}

.icon-tick:before {
  content: "\e916";
}

.icon-up-arrow:before {
  content: "\e917";
}

.icon-call:before {
  content: "\e90e";
}

.icon-edit:before {
  content: "\e90f";
}

.icon-email:before {
  content: "\e910";
}

.icon-left-arrow:before {
  content: "\e90c";
}

.icon-right-arrow:before {
  content: "\e90d";
}

.icon-close:before {
  content: "\e90b";
}

.icon-more-f:before {
  content: "\e90a";
}

.icon-add:before {
  content: "\e905";
}

.icon-business-forecast:before {
  content: "\e906";
}

.icon-logout:before {
  content: "\e907";
}

.icon-notify:before {
  content: "\e908";
}

.icon-swap:before {
  content: "\e909";
}

.icon-analyse:before {
  content: "\e901";
}

.icon-more:before {
  content: "\e902";
}

.icon-plan:before {
  content: "\e903";
}

.icon-track:before {
  content: "\e904";
}

.icon-checklist:before {
  content: "\e900";
}
